import language from 'commons/js/locale'
import country from 'commons/js/country'

import {
    UnauthorizedError,
    ForbiddenError,
    NotFoundError,
    ServerError,
    InvalidFormatError,
    UnprocessableEntityError,
    TimeoutError,
    EmptyError,
    AlreadyBookedError,
    NumberPlateNotInWhiteListError,
    MaxCouponsExceededError,
    BookingInThePastError,
    CreditCardInvalidError,
    UnavailableParking,
} from './errors'
import { getConstant } from 'commons/js/constants'

const TIMEOUT = 60 * 1000 // 60s

const defaultHeaders = () => ({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': language,
    'X-Country': country,
})

const multipartHeaders = () => ({
    'Accept': 'application/json',
    'Accept-Language': language,
    'X-Country': country,
})

const csrfHeaders = () => ({
    'X-csrf-protection': getConstant('CSRF_PROTECTION_HEADER'),
})

function addData (form, name, item) {
    if (item instanceof window.File) {
        form.append(name, item, item.name)
    } else {
        form.append(name, item)
    }
}

function serializeFormData (data) {
    return Object.keys(data).reduce((form, name) => {
        if (Array.isArray(data[name])) {
            data[name].forEach((item) => {
                addData(form, `${name}`, item)
            })
        } else {
            addData(form, name, data[name])
        }
        return form
    }, new window.FormData())
}

async function wrapResponse (response) {
    const responseText = await response.text()

    if (response.status === 204) {
        throw new EmptyError()
    }

    if (response.status === 401) {
        throw new UnauthorizedError()
    }

    if (response.status === 403) {
        throw new ForbiddenError()
    }

    if (response.status === 404) {
        throw new NotFoundError()
    }

    if (response.status === 453) {
        throw new BookingInThePastError()
    }

    if (response.status === 457) {
        throw new AlreadyBookedError()
    }

    if (response.status === 445) {
        throw new NumberPlateNotInWhiteListError()
    }

    if (response.status === 462) {
        throw new MaxCouponsExceededError()
    }

    if (response.status === 474) {
        throw new CreditCardInvalidError()
    }

    if (response.status === 492) {
        throw new UnavailableParking()
    }

    if (response.status >= 500) {
        throw new ServerError()
    }

    let result
    try {
        if (responseText.length === 0) {
            result = {}
        } else {
            result = JSON.parse(responseText)
        }
    } catch (parseError) {
        throw new InvalidFormatError()
    }

    if (!response.ok) {
        throw new UnprocessableEntityError(result)
    }

    return { result }
}

export function fetch (url, { timeout = TIMEOUT, ...restOptions }) {
    return Promise.race([
        window.fetch(url, restOptions),
        new Promise((resolve, reject) => setTimeout(reject, timeout, new TimeoutError())),
    ])
}

export async function get ({ url, options = {} }) {
    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: defaultHeaders(),
        ...options,
    })
    return wrapResponse(response)
}

export async function post ({ url, data, headers, options = {} }) {
    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { ...defaultHeaders(), ...csrfHeaders(), ...headers },
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function postMultipart ({ url, data, headers, options = {} }) {
    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { ...multipartHeaders(), ...csrfHeaders(), ...headers },
        body: serializeFormData(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function put ({ url, data, headers, options = {} }) {
    const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: { ...defaultHeaders(), ...csrfHeaders(), ...headers },
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function patch ({ url, data, headers, options = {} }) {
    const response = await fetch(url, {
        method: 'PATCH',
        credentials: 'include',
        headers: { ...defaultHeaders(), ...csrfHeaders(), ...headers },
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function del ({ url, data, headers, options = {} }) {
    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: { ...defaultHeaders(), ...csrfHeaders(), ...headers },
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}
