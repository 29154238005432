export class EmptyError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, EmptyError)
        }

        this.name = 'EmptyError'
    }
}
export class UnauthorizedError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnauthorizedError)
        }

        this.name = 'UnauthorizedError'
    }
}

export class ForbiddenError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ForbiddenError)
        }

        this.name = 'ForbiddenError'
    }
}

export class NotFoundError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NotFoundError)
        }

        this.name = 'NotFoundError'
    }
}

export class ServerError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError)
        }

        this.name = 'ServerError'
    }
}

export class InvalidFormatError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidFormatError)
        }

        this.name = 'InvalidFormatError'
    }
}

export class TimeoutError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TimeoutError)
        }

        this.name = 'TimeoutError'
    }
}

export class UnprocessableEntityError extends Error {
    constructor (response, ...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnprocessableEntityError)
        }

        this.response = response
        this.name = 'UnprocessableEntityError'
    }
}

export class AlreadyBookedError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AlreadyBookedError)
        }

        this.name = 'AlreadyBookedError'
    }
}
export class NumberPlateNotInWhiteListError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NumberPlateNotInWhiteListError)
        }

        this.name = 'NumberPlateNotInWhiteListError'
    }
}

export class MaxCouponsExceededError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, MaxCouponsExceededError)
        }

        this.name = 'MaxCouponsExceededError'
    }
}

export class BookingInThePastError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BookingInThePastError)
        }

        this.name = 'BookingInThePastError'
    }
}
export class CreditCardInvalidError extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CreditCardInvalidError)
        }

        this.name = 'CreditCardInvalidError'
    }
}

export class UnavailableParking extends Error {
    constructor (...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnavailableParking)
        }

        this.name = 'UnavailableParking'
    }
}
